import axios from "axios";
import { handleErrorResponse } from "./error-handler";

// export const status = {
//     idle: 'idle',
//     loading: 'loading',
//     success: 'success',
//     error: 'error',
// };

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL_API, // set the base URL for all requests
});

export const login = async (email, password, type, rememberMe, comId) => {
	try {
		const $response = await axiosInstance.post("/login", {
			email: email,
			password: password,
			type: type,
			comId : comId,
			remember : rememberMe
		});
		return $response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// company social media on login page get by domain
export const getSocialMediaDomain = async (domain) => {
	try {
		const response = await axiosInstance.get("/attribute/data-company", {
			params: {
				domain: domain,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const codeVerify = async (code) => {
	try {
		const response = await axiosInstance.post("/verification", {
			code: code,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const codeResend = async (email) => {
	try {
		const response = await axiosInstance.post("/resend-verification", {
			email: email,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const branch = async (comId) => {
	try {
		const response = await axiosInstance.get("/setup/company-store", {
			params: {
				comId: comId,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const region = async () => {
	try {
		const response = await axiosInstance.get("/attribute/locations");

		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// ----------------------fixed config----------------------
// list phone code +60 available in system
export const getListPhoneCode = async () => {
	try {
		const response = await axiosInstance.get("/attribute/phone-code");
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// list bank details maybank, cimb etc available in system
export const getListBankDetail = async () => {
	try {
		const response = await axiosInstance.get("/attribute/list-bank");
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// list state perak, other etc available in system
export const getListState = async () => {
	try {
		const response = await axiosInstance.get("/attribute/state");
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// ----------------------dashboard----------------------
// hq dashboard



// agent dashboard
// get user info
export const getAgentDashboardSummary = async (dataSent) => {
	let data = {
		comId: dataSent.comId,
		date_start: dataSent.startDate,
		date_end: dataSent.endDate
	}
	try {
		const response = await axiosInstance.get("/dashboard/summary", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};


// ----------------------user details----------------------
// get user info
export const getUserInfo = async (agentId) => {
	try {
		const response = await axiosInstance.get("/user/detail", {
			params: {
				id: agentId,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save user social media
export const saveUserSocMed = async (
	sentsocmedUrlFacebook,
	sentsocmedUrlInstagram,
	sentsocmedUrlTiktok,
	sentsocmedUrlTwitter
) => {
	const data = {
		socmedUrlFacebook: sentsocmedUrlFacebook,
		socmedUrlInstagram: sentsocmedUrlInstagram,
		socmedUrlTiktok: sentsocmedUrlTiktok,
		socmedUrlTwitter: sentsocmedUrlTwitter,
	};
	try {
		const response = await axiosInstance.post("/user/social-network", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save user details
// amir:missing email
export const saveUserDetail = async (
	companyCountry,
	sentaccFirstName,
	sentaccLastName,
	sentaccIdentity,
	sentaccBirthDate,
	sentaccGender,
	sentaccCodePhone,
	sentaccPhone,
	sentaccAddressLine1,
	sentaccAddressLine2,
	sentaccAddressPostcode,
	sentaccAddressCity,
	sentaccAddressState,
	sentaccAddressCountry,
	sentaccAddressProvince,
	sentaccAddressRegency,
	sentaccAddressDisctrict,
	sentaccAddressVillage,
) => {
	const data = {
		companyCountry: companyCountry,
		accFirstName: sentaccFirstName,
		accLastName: sentaccLastName,
		accIdentity: sentaccIdentity,
		accBirthDate: sentaccBirthDate,
		accGender: sentaccGender,
		accCodePhone: sentaccCodePhone,
		accPhone: sentaccPhone,
		accAddressLine1: sentaccAddressLine1,
		accAddressLine2: sentaccAddressLine2,
		accAddressPostcode: sentaccAddressPostcode,
		accAddressCity: sentaccAddressCity,
		accAddressState: sentaccAddressState,
		accAddressCountry: sentaccAddressCountry,
		accAddressProvince: sentaccAddressProvince,
		accAddressRegency: sentaccAddressRegency,
		accAddressDistrict: sentaccAddressDisctrict,
		accAddressVillage: sentaccAddressVillage,
	};
	try {
		const response = await axiosInstance.post("/user/account-detail", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save user bank account
// amir:what is bank company
export const saveUserBankDetail = async (
	sentaccBankCountry,
	sentaccBankCompany,
	sentaccBankNumber,
	sentaccBankName,
	sentaccBankIdentity
) => {
	const data = {
		accBankCountry: sentaccBankCountry,
		accBankCompany: sentaccBankCompany,
		accBankNumber: sentaccBankNumber,
		accBankName: sentaccBankName,
		accBankIdentity: sentaccBankIdentity,
	};
	try {
		const response = await axiosInstance.post("/user/bank-detail", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//save user image
export const saveUserImg = async (sentaccImage1) => {
	let formData = new FormData();
	formData.append("accImage1", sentaccImage1);
	try {
		const response = await axiosInstance.post("/user/upload-image", formData);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save user password
// amir: missing current password
export const forgetUserPassword = async (sentemail, url, domain) => {
	const data = {
		email: sentemail,
		url: url,
		domain: domain
	};
	try {
		const response = await axiosInstance.post("/forgot-password", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const createNewPassword = async (dataSent) => {
	const data = {
		token: dataSent.token,
		password: dataSent.password,
	};
	try {
		const response = await axiosInstance.post("/create-password", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// forget user password
// amir: missing forget password api
export const saveUserPassword = async (sentpassword) => {
	const data = {
		password: sentpassword,
	};
	try {
		const response = await axiosInstance.post("/user/change-password", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting analytic dashboard
// dashboard
// total sales and total target
export const getTsalesTtarget = async (comId, date_start, date_end, tierId) => {
	try {
		const response = await axiosInstance.get(
			`/dashboard/sales-target?comId=${comId}&date_start=${date_start}&date_end=${date_end}&tierId=${tierId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// number of user each level
export const getNoUserLevel = async (date_start, date_end, comId) => {
	try {
		const response = await axiosInstance.get(
			`/dashboard/total-user?date_start=${date_start}&date_end=${date_end}&comId=${comId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// total of user and total quota user
export const getTUserTquota = async (comId, date_start, date_end) => {
	try {
		const response = await axiosInstance.get(
			`/dashboard/total-user?comId=${comId}&date_start=${date_start}&date_end=${date_end}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// promotion banner
export const getPromoBanner = async (comId) => {
	try {
		const response = await axiosInstance.get(
			`/dashboard/promotion-banner?comId=${comId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// top ranking personal sales
export const getTrankingPS = async (comId) => {
	try {
		const response = await axiosInstance.get(
			`/dashboard/top-ranking-ps?comId=${comId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// total user login
export const getTlogin = async (comId, date_start, date_end) => {
	try {
		const response = await axiosInstance.get(
			`/dashboard/user-login?comId=${comId}&date_start=${date_start}&date_end=${date_end}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// user upgrade historical
export const getUpgradeUser = async (comId, date_start, date_end) => {
	try {
		const response = await axiosInstance.get(
			`/dashboard/level-history?comId=${comId}&date_start=${date_start}&date_end=${date_end}&type='promote'`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// user downgrade historical
export const getDowngradeUser = async (comId, date_start, date_end) => {
	try {
		const response = await axiosInstance.get(
			`/dashboard/level-history?comId=${comId}&date_start=${date_start}&date_end=${date_end}&type='demote'`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// --------------------------------------------------------
// product inventory
// product inventory list
export const getProductInventoryList = async (comId, pages, status, type, branch, search, sort, variation = null) => {
	try {
		let url = `/inventory?comId=${comId}&page=${pages}`;

		if (status) {
			url += `&status=${status}`;
		}

		if (type) {
			url += `&type=${type}`;
		}

		if (branch) {
			url += `&storeId=${branch}`;
		}

		if (search) {
			url += `&search=${search}`;
		}
		if (sort) {
			url += `&sort=${sort}`;
		}
		if (variation) {
			url += `&variation=${variation}`;
		}

		const response = await axiosInstance.get(url);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getSelectedProductInventoryList = async (comId, pages, productId, branch, search) => {
	try {
		let url = `/inventory/select?comId=${comId}&page=${pages}`;

		if (productId) {
			url += `&productsId=${productId}`;
		}

		if (branch) {
			url += `&storeId=${branch}`;
		}

		if (search) {
			url += `&search=${search}`;
		}

		const response = await axiosInstance.get(url);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const updateProductInventory = async (
	sentstoreId,
	sentproductId,
	sentId,
	sentqtyIn,
	sentqtyOut,
	sentinvType,
	sentinvDesc,
	sentVariation
) => {
	let data = {};
	if (sentVariation != null) {
		if (sentVariation[1]) {
			if (sentVariation[2]) {
				data = {
					storeId: sentstoreId,
					productId: sentproductId,
					id: sentId,
					qtyIn: sentqtyIn,
					qtyOut: sentqtyOut,
					invType: sentinvType,
					invDesc: sentinvDesc,
					variationOptId1: sentVariation[0].var_opt_id,
					variationOptId2: sentVariation[1].var_opt_id,
					variationOptId3: sentVariation[2].var_opt_id,
				};
			} else {
				data = {
					storeId: sentstoreId,
					productId: sentproductId,
					id: sentId,
					qtyIn: sentqtyIn,
					qtyOut: sentqtyOut,
					invType: sentinvType,
					invDesc: sentinvDesc,
					variationOptId1: sentVariation[0].var_opt_id,
					variationOptId2: sentVariation[1].var_opt_id,
				};
			}
		} else {
			data = {
				storeId: sentstoreId,
				productId: sentproductId,
				id: sentId,
				qtyIn: sentqtyIn,
				qtyOut: sentqtyOut,
				invType: sentinvType,
				invDesc: sentinvDesc,
				variationOptId1: sentVariation[0].var_opt_id,
			};
		}
	} else {
		data = {
			storeId: sentstoreId,
			productId: sentproductId,
			id: sentId,
			qtyIn: sentqtyIn,
			qtyOut: sentqtyOut,
			invType: sentinvType,
			invDesc: sentinvDesc,
		};
	}
	try {
		const response = await axiosInstance.post("/inventory/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};














// --------------------------------------------------------
// reporting
// transaction summary statistic (done)
export const getTransactionStat = async (comId, month, year, date, dateType) => {
	try {
		const response = await axiosInstance.get(
			`/transaction/summary?comId=${comId}&month=${month}&year=${year}&date=${date}&dateType=${dateType}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// transaction list (done)
export const getTransactionSumList = async (
	comId,
	page = null,
	payment_method = null,
	tier = null,
	date = null,
	search = null,
	dateType = null
) => {
	try {
		const response = await axiosInstance.get('/transaction/list', {
			params: {
				comId: comId,
				page: page,
				payment_method: payment_method,
				tier: tier,
				date: date,
				search: search,
				dateType: dateType
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const downloadTransactionSumList = async (
	comId,
	page = null,
	payment_method = null,
	tier = null,
	date = null,
	search = null,
	dateType = null
) => {
	try {
		const response = await axiosInstance.post('/transaction/list/download',
			{
				params: {
					comId: comId,
					page: page,
					payment_method: payment_method,
					tier: tier,
					date: date,
					search: search,
					dateType: dateType
				},
			}
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// transaction list for all
// amir: missing api
export const getAllTransactionSumList = async (
	comId,
	page = null,
	payment_method = null,
	tier = null,
	date = null,
	search = null,
	dateType = null
) => {
	try {
		const response = await axiosInstance.get('/transaction/list', {
			params: {
				comId: comId,
				page: page,
				payment_method: payment_method,
				tier: tier,
				date: date,
				search: search,
				dateType: dateType
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// transaction payment option list
export const getTransactionSumPayment = async (comId) => {
	try {
		const response = await axiosInstance.get(`/transaction/payment-method?comId=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// --------------------------------------------------------
// reporting recruit statistic (done)
export const getRecruitStat = async (comId, date) => {
	try {
		const response = await axiosInstance.get(
			`/recruitment/summary?comId=${comId}&date=${date}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting recruit list (done)
export const getRecruitList = async (
	comId,
	page = null,
	search = null,
	tier = null,
	date = null,

) => {
	try {
		const response = await axiosInstance.get('/recruitment/list', {
			params: {
				comId: comId,
				page: page,
				level: tier,
				date: date,
				search: search
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting recruit list all
// amir: missing api
export const getAllRecruitList = async (
	comId,
	page = null,
	search = null,
	tier = null,
	date = null,
) => {
	try {
		const response = await axiosInstance.get('/recruitment/list', {
			params: {
				comId: comId,
				page: page,
				level: tier,
				date: date,
				search: search
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// --------------------------------------------------------
// reporting kpi statistic (done)
export const getKpiReportStat = async (comId, month, year) => {
	try {
		const response = await axiosInstance.get(`/kpi/summary?comId=${comId}&month=${month}&year=${year}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting kpi list (done)
export const getKpiReportList = async (
	comId,
	page = null,
	search = null,
	tier = null,
	dateType = null,
	month = null,
	year = null,
) => {
	try {
		const response = await axiosInstance.get('/kpi/sales-list', {
			params: {
				comId: comId,
				page: page,
				search: search,
				tier: tier,
				dateType: dateType,
				month: month,
				year: year,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting kpi list all
// amir: missing api
export const getAllKpiReportList = async (
	comId,
	page = null,
	search = null,
	tier = null,
	dateType = null,
	month = null,
	year = null,
) => {
	try {
		const response = await axiosInstance.get('/kpi/sales-list', {
			params: {
				comId: comId,
				page: page,
				search: search,
				tier: tier,
				dateType: dateType,
				month: month,
				year: year,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// --------------------------------------------------------
// reporting agent database statistic (done)
export const getAgentDbStat = async (comId, date = null) => {
	try {
		const response = await axiosInstance.get(
			`/agent-report/summary?comId=${comId}&date=${date}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting agent database list (done)
export const getAgentDbList = async (
	comId,
	page = null,
	tierId = null,
	search = null,
	type = null,
	date = null,
	status = null
) => {
	try {
		const statusParam = status !== 'All' ? status : null;
		const response = await axiosInstance.get('/agent-report/list', {
			params: {
				comId: comId,
				page: page,
				tierId: tierId,
				search: search,
				type: type,
				date: date,
				status: statusParam,
			}
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting agent database list all
// amir: missing api
export const getAllAgentDbList = async (
	comId,
	page = null,
	tier = null,
	search = null,
	type = null,
	date = null,
	status = null
) => {
	try {
		const statusParam = status !== 'All' ? status : null;
		const response = await axiosInstance.get(
			'/agent-report/list', {
			params: {
				comId: comId,
				page: page,
				tier: tier,
				search: search,
				type: type,
				date: date,
				status: statusParam,
			}
		}
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// --------------------------------------------------------
// reporting customer database statistic (done)
export const getCustomerDbStat = async (comId, date) => {
	try {
		const response = await axiosInstance.get(
			`/customer-report/summary?comId=${comId} & date=${date}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting customer database list (done)
export const getCustomerDbList = async (
	comId,
	page = null,
	search = null,
	country = null,
	state = null,

) => {
	try {
		const response = await axiosInstance.get(
			'/customer-report/list', {
			params: {
				comId: comId,
				page: page,
				search: search,
				country: country,
				state: state

			}
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting customer database list all
// amir: missing api
export const getAllCustomerDbList = async (
	comId,
	page = null,
	search = null,
	country = null,
) => {
	try {
		const response = await axiosInstance.get(
			'/customer-report/list', {
			params: {
				comId: comId,
				page: page,
				search: search,
				country: country
			}
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// --------------------------------------------------------
// reporting inventory statistic (done)
export const getInventoryStat = async (comId) => {
	try {
		const response = await axiosInstance.get(`/report/inventory/summary?comId=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting inventory list (done)
export const getInventoryList = async (
	comId,
	page = null,
	search = null,
	variation = null,
	date = null
) => {
	try {
		const response = await axiosInstance.get('/report/inventory/list', {
			params: {
				comId: comId,
				page: page,
				search: search,
				variation: variation,
				date: date,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting inventory list all
// amir: missing api
export const getAllInventoryList = async (
	comId,
	page = null,
	search = null,
	variation = null
) => {
	try {
		const response = await axiosInstance.get('/report/inventory/list', {
			params: {
				comId: comId,
				page: page,
				search: search,
				variation: variation
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// --------------------------------------------------------
// Analytic
// analytic sales statistic (done)
export const getSalesSummaryStat = async (comId, date) => {
	try {
		const response = await axiosInstance.get(`/analytic/sales/summary?comId=${comId}&date=${date}&dateType=ord_paid`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic graph (done)
export const getSalesSummaryGraph = async (date, comId, type) => {
	try {
		const response = await axiosInstance.get(`/analytic/sales/graph?date=${date}&comId=${comId}&type=${type}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic sales list (done)
export const getSalesSummaryList = async (comId, search, page, date) => {
	try {
		search = search || '';
		page = page || '';
		const response = await axiosInstance.get(`/analytic/sales/list?comId=${comId}&search=${search}&page=${page}&date=${date}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic sales list all
export const getAllSalesSummaryList = async (comId, search, page) => {
	try {
		search = search || '';
		page = page || '';
		const response = await axiosInstance.get(`/analytic/sales/list?comId=${comId}&search=${search}&page=${page}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// --------------------------------------------------------
// analytic category statistic (done)
export const getCategoryGraph = async (comId, date) => {
	try {
		const response = await axiosInstance.get(`/analytic/category/graph-category?comId=${comId}&date=${date}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic category list (done)
export const getCategoryList = async (comId, search = null, page = null, limit = 10, usrOrgId = null, date = null) => {
	try {
		const response = await axiosInstance.get('/analytic/category/list', {
			params: {
				comId: comId,
				search: search,
				page: page,
				limit: limit,
				usrOrgId: usrOrgId,
				date: date
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic category list all
export const getAllCategoryList = async (comId, search, page, date) => {
	try {
		search = search || '';
		page = page || '';
		const response = await axiosInstance.get(`/analytic/category/list?comId=${comId}&search=${search}&page=${page}&date=${date}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// --------------------------------------------------------
// analytic product statistic (done)
export const getProductGraph = async (comId, date) => {
	try {
		const response = await axiosInstance.get(`/analytic/product/graph-product?comId=${comId}&date=${date}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
// analytic product list (done)
export const getProductList = async (comId, search = null, page = null, order_by_type = null, type = null, date = null) => {
	try {
		const response = await axiosInstance.get('/analytic/product/list', {
			params: {
				comId: comId,
				search: search,
				page: page,
				order_by_type: order_by_type,
				type: type,
				date: date
			}
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
// analytic product list all
export const getAllProductList = async (comId, search = null, page = null, usrOrgId = null, date = null) => {
	try {
		const response = await axiosInstance.get('/analytic/product/list', {
			params: {
				comId: comId,
				search: search,
				page: page,
				usrOrgId: usrOrgId,
				date: date
			}
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// --------------------------------------------------------
// analytic states sales list
export const getStateSalesList = async (comId, search, page) => {
	try {
		search = search || '';
		page = page || '';
		const response = await axiosInstance.get(`/analytic/state/sales-list?comId=${comId}&search=${search}&page=${page}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic states sales list all
export const getAllStateSalesList = async (comId, search, page) => {
	try {
		search = search || '';
		page = page || '';
		const response = await axiosInstance.get(`/analytic/state/sales-list?comId=${comId}&search=${search}&page=${page}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic states recruit list
export const getStateRecruitList = async (comId, search, page) => {
	try {
		search = search || '';
		page = page || '';
		const response = await axiosInstance.get(`/analytic/state/age-recruit-list?comId=${comId}&search=${search}&page=${page}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic states recruit list all
export const getAllStateRecruitList = async (comId, search, page) => {
	try {
		search = search || '';
		page = page || '';
		const response = await axiosInstance.get(`/analytic/state/age-recruit-list?comId=${comId}&search=${search}&page=${page}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// --------------------------------------------------------
// analytic age sales graph
export const getSalesAgeGraph = async (comId) => {
	try {
		const response = await axiosInstance.get(`/analytic/age/graph-sale?comId=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic age sales list
export const getSalesAgeList = async (comId, search, page) => {
	try {
		search = search || '';
		page = page || '';
		const response = await axiosInstance.get(`/analytic/age/sale-list?comId=${comId}&search=${search}&page=${page}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic age sales list all
export const getAllSalesAgeList = async (comId, search, page) => {
	try {
		search = search || '';
		page = page || '';
		const response = await axiosInstance.get(`/analytic/age/sale-list?comId=${comId}&search=${search}&page=${page}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic age recruit graph
export const getRecruitAgeGraph = async (comId) => {
	try {
		const response = await axiosInstance.get(`/analytic/age/graph-recruit?comId=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic age recruit list
export const getRecruitAgeList = async (comId, search = null, page = null, priode = null) => {
	try {
		const response = await axiosInstance.get('/analytic/age/recruit-list', {
			params: {
				comId: comId,
				search: search,
				page: page,
				priode: priode
			}
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// analytic age recruit list all
export const getAllRecruitAgeList = async (comId, search, page) => {
	try {
		search = search || '';
		page = page || '';
		const response = await axiosInstance.get(`/analytic/age/recruit-list?comId=${comId}&search=${search}&page=${page}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};















// --------------------------------------------------------
// reporting commission statistic
// amir: missing api
export const getCommissionStat = async () => {
	try {
		const response = await axiosInstance.get(`/report/inventory/summary`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting commission list
export const getCommissionList = async (page, search, tier, month, year, commission) => {
	console.log(tier)
	try {
		const response = await axiosInstance.get('/commission', {
			params: {
				page: page,
				search: search,
				tier: tier,
				month: month,
				year: year,
				commission: commission
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// reporting commission list all
// amir: missing api
export const getAllCommissionList = async (search, month, year, type = 'paginate', commission = null) => {
	try {
		const response = await axiosInstance.get(`/commission`,{
			params:{
				search: search,
				month: month,
				year: year,
				type: type,
				commission: commission
			}
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// kpi user
export const getKpiUser = async (comId, type) => {
	try {
		const response = await axiosInstance.get(
			`/report/kpi?comId=${comId}&type=${type}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};



// --------------------agent management--------------------
// agent database
// export const getAgentList = async (comId, searchDetail, statusAcc, tierId) => {
export const getAgentList = async (comId, page, searchDetail, userId) => {
	try {
		const response = await axiosInstance.get(
			`agent-database`,
			{
				params: {
					comId: comId,
					page: page,
					search: searchDetail,
					userId: userId,
				},
			}
			// `agent-database?comId=${comId}&search=${searchDetail}&status=${statusAcc}&tier=${tierId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// agent registration
export const saveNewUser = async (
	sentcomId,
	sentusrTierId,
	sentfamilyDirectId,
	sentfamilyHelperId,
	sentusername,
	sentemail,
	sentaccCategory,
	sentaccFirstName,
	sentaccLastName,
	sentaccCompany,
	sentaccIdentity,
	sentaccCodePhone,
	sentaccPhone
) => {
	const data = {
		comId: sentcomId,
		usrTierId: sentusrTierId,
		familyDirectId: sentfamilyDirectId,
		familyHelperId: sentfamilyHelperId,
		username: sentusername,
		email: sentemail,
		accCategory: sentaccCategory,
		accFirstName: sentaccFirstName,
		accLastName: sentaccLastName,
		accCompany: sentaccCompany,
		accIdentity: sentaccIdentity,
		accCodePhone: sentaccCodePhone,
		accPhone: sentaccPhone,
	};
	try {
		const response = await axiosInstance.post("/user/self-register", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getAgentFamilyTree = async (agentId = null, agentName = null) => {
	try {
		const response = await axiosInstance.get("/user/family-tree", {
			params: {
				id: agentId,
				agentName: agentName,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getListAgent = async (comId, tierLeader, usrLvl) => {
	try {
		const response = await axiosInstance.get("/attribute/list-agent", {
			params: {
				comId: comId,
				tierLeader: tierLeader,
				userLvl: usrLvl
			}
		})

		return response
	} catch (error) {
		throw handleErrorResponse(error)
	}
}

// -----------------------setup tier-----------------------
// company details api
// get all tier in company
export const getAllTier = async (comId, type = null) => {
	try {
		const response = await axiosInstance.get("/setup/tier", {
			params: {
				comId: comId,
				type: type,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getCommissionRank = async (id = null) => {
	try {
		const response = await axiosInstance.get("/setup/company-commission-rank", {
			params: {
				id: id,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get all commission rank in company
export const getAllRank = async (comId) => {
	try {
		const response = await axiosInstance.get("/setup/tier/getRank", {
			params: {
				comId: comId,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const updateCommissionRank = async (data) => {
	const result = {
		id: data.id,
		commission_name: data.commission_name,
		personal_sales: data.personal_sales,
		total_personal_group_sales: data.total_personal_group_sales,
		side_group_sales: data.side_group_sales,
		commission: data.commission,
		personal_level_commission: data.personal_level_commission,
		is_leadership: data.is_leadership,
		benefit_1: data.benefit_1,
		benefit_2: data.benefit_2,
		benefit_3: data.benefit_3,
		benefit_4: data.benefit_4,
		benefit_5: data.benefit_5,
		benefit_6: data.benefit_6,
		benefit_7: data.benefit_7,
		benefit_8: data.benefit_8
	};
	try {
		const response = await axiosInstance.post("setup/company-commission-rank/update", result);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get all payment available in company
// amir: missing api
export const getIntegrationSetupConfig = async () => {
	try {
		const response = await axiosInstance.get(
			`integration/setup/config`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const disableSetupIntegration = async (setupId) => {
	const data = {
		setupId: setupId,

	};
	try {
		const response = await axiosInstance.post("integration/setup/disable", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getEjenSales = async (usr_org_id, com_id) => {
	try {
		const response = await axiosInstance.get("/zerorise/user-sales", {
			params: {
				usr_org_id: usr_org_id,
				com_id: com_id,
			},
		});

		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const storeIntegrationEjenPaySetupConfig = async (idNo, userName, bankCode, accountNo, integrationTypeId) => {
	const data = {
		idNo: idNo,
		userName: userName,
		bankCode: bankCode,
		accountNo: accountNo,
		integrationTypeId: integrationTypeId,
		status: 'active',

	};
	try {
		const response = await axiosInstance.post("integration/ejenpay/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const setAutoFill = async (comId, integrationId) => {
	const data = {
		comId: comId,
		integrationId: integrationId
	};
	try {
		const response = await axiosInstance.post("integration/ejenpay/details", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const setAutoFillShipping = async (comId, integrationId, url) => {
	try {
		const response = await axiosInstance.get(url, {
			params: {
				comId: comId,
				integrationName: integrationId
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getPosLaju = async () => {
	try {
		const response = await axiosInstance.get("/integration/poslaju/setup");
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const storeIntegrationBillPlzSetupConfig = async (apiKey, collectionId, integrationTypeId) => {
	const data = {
		apiKey: apiKey,
		collectionId: collectionId,
		integrationTypeId: integrationTypeId,
		status: 'active',

	};
	try {
		const response = await axiosInstance.post("integration/billplz/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const storeIntegrationDelyvaSetupConfig = async (apiKey, customerId, userId, companyId, companyCode, integrationTypeId) => {
	const data = {
		apiKey: apiKey,
		customerId: customerId,
		userId: userId,
		companyId: companyId,
		companyCode: companyCode,
		integrationTypeId: integrationTypeId,
		status: 'active',

	};
	try {
		const response = await axiosInstance.post("integration/delyva/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const storeIntegrationNinjavanSetupConfig = async (apiKey, clientId, clientKey, integrationTypeId) => {
	const data = {
		apiKey: apiKey,
		clientId: clientId,
		clientKey: clientKey,
		status: 'active',
		integrationTypeId: integrationTypeId
	};
	try {
		const response = await axiosInstance.post("integration/ninjavan/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const storeIntegrationCitilinkSetupConfig = async (datax) => {
	const data = datax;
	try {
		const response = await axiosInstance.post("integration/citylink/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const storeIntegrationEasyParcelSetupConfig = async (datax) => {
	const data = datax;
	try {
		const response = await axiosInstance.post("integration/easyparcel/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const storeIntegrationPosLajuSetupConfig = async (clientId, clientSecret, applicationCode, secretId, username, accountNo, subscriptionCode) => {
	try {
		const data = {
			client_id: clientId,
			client_secret : clientSecret,
			application_code : applicationCode,
			secret_id : secretId,
			username : username,
			account_no : accountNo,
			subscription_code : subscriptionCode
		};
		const response = await axiosInstance.post("integration/poslaju/setup", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const storeCourierEasyParcel = async (datax) => {
	const data = datax;
	try {
		const response = await axiosInstance.post("integration/easyparcel/storecourier", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getEasyParcelCourier = async (comId, integrationName) => {
	try {
		const data = {
			comId: comId,
			integrationName: integrationName,
		};
		const response = await axiosInstance.post(`integration/easyparcel/checkrate`, data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get all payment available in company
// amir: missing api
export const getAllPayment = async (comId) => {
	try {
		const response = await axiosInstance.get(
			`integration/payment?comId=${comId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// Count Company Money Log (Deposit and Payout)
export const getCompanyBalance = async () => {
	try {
		const response = await axiosInstance.get(`/company-money/balance`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const requestPayout = async (ids_array) => {
	try {
		const response = await axiosInstance.post(
			"/commission/request-payout", ids_array
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get tier by id in company
export const getTierId = async (tierId) => {
	try {
		const response = await axiosInstance.get(`setup/tier?id=${tierId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// update tier in company
export const saveTier = async (dataTier) => {
	const data = {
		id: dataTier.level_id,
		levelName: dataTier.level_name,
		rankNumber: dataTier.rank,
		momUnit: dataTier.moq_unit,
		momAmount: dataTier.moq_sales,
		psUnit: dataTier.personal_sales_unit,
		psAmount: dataTier.personal_sales_rm,
		gsUnit: dataTier.group_sales_unit,
		gsAmount: dataTier.group_sales_rm,
		tpgsUnit: dataTier.tpgs_sales_unit,
		tpgsAmount: dataTier.tpgs_sales_rm,
		helperPercen: dataTier.helper,
		recruitComm: dataTier.recruit_commission,
		leadership: dataTier.leadership ?? null,
	};
	try {
		const response = await axiosInstance.post("setup/tier/update", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//store commission rank
export const saveRank = async (dataRank) => {
	const data = {
		id: dataRank.id,
		level: dataRank.level,
		colour: dataRank.colour_code,
		rank: dataRank.rank,
		minOrder: dataRank.min_order_amount,
		firstOrder: dataRank.first_order_amount,
		ps: dataRank.personal_sales_amount,
		sgs: dataRank.side_group_sales_amount,
		tpgs: dataRank.total_personal_group_sales,
		introComm: dataRank.introducer_commission,
		levelOrder: dataRank.level_order,
		commPercent: dataRank.commission_percentage,
		partnership: dataRank.partnership,
	};
	try {
		const response = await axiosInstance.post("setup/tier/storeRank", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
export const newTier = async (company_id, dataTier) => {
	const data = {
		comId: company_id,
		levelName: dataTier.level_name,
		rankNumber: dataTier.rank,
		momUnit: dataTier.moq_unit,
		momAmount: dataTier.moq_sales,
		psUnit: dataTier.personal_sales_unit,
		psAmount: dataTier.personal_sales_rm,
		gsUnit: dataTier.group_sales_unit,
		gsAmount: dataTier.group_sales_rm,
		tpgsUnit: dataTier.tpgs_sales_unit,
		tpgsAmount: dataTier.tpgs_sales_rm,
		helperPercen: dataTier.helper,
		recruitComm: dataTier.recruit_commission,
		momFirstOrderUnit: dataTier.moq_unit,
		momFirstOrderAmount: dataTier.moq_sales,
	};
	try {
		const response = await axiosInstance.post("setup/tier/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// -----------------------setup product-----------------------
// category product api
// get category product
export const getCategoryProduct = async (comId, storeId) => {
	try {
		const response = await axiosInstance.get(
			`setup/product/category?comId=${comId}&storeId=${storeId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save category product
export const saveCategoryProduct = async (
	sentComId,
	sentStoreId,
	sentProductType
) => {
	const data = {
		comId: sentComId,
		storeId: sentStoreId,
		productType: sentProductType,
	};
	try {
		const response = await axiosInstance.post(
			"setup/product/category/store",
			data
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete category product
export const deleteCategotyProduct = async (
	sentComId,
	sentStoreId,
	sentProductType
) => {
	const data = {
		comId: sentComId,
		storeId: sentStoreId,
		productType: sentProductType,
	};
	try {
		const response = await axiosInstance.delete(
			"setup/product/category/delete",
			{ data }
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// product details api
// get product details
export const getProductDetails = async (comId) => {
	try {
		const response = await axiosInstance.get(
			`setup/product/detail?comId=${comId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save product details
export const saveProductDetails = async (
	sentComId,
	sentStoreId,
	sentProductType,
	sentProductSku,
	sentProductName,
	sentProductVolume,
	sentProductWeight,
	sentProductStatus,
	sentProductDesc,
	sentPublishTime,
	sentVariant,
	sentPriceRange
) => {
	const data = {
		comId: sentComId,
		storeId: sentStoreId,
		productType: sentProductType,
		productSku: sentProductSku,
		productName: sentProductName,
		productVolume: sentProductVolume,
		productWeight: sentProductWeight,
		productStatus: sentProductStatus,
		productDesc: sentProductDesc,
		publishTime: sentPublishTime,
		variant: sentVariant,
		priceRange: sentPriceRange,
	};
	try {
		const response = await axiosInstance.post(
			"setup/product/detail/store",
			data
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete product details
export const deleteProductDetails = async (sentProductId, sentType) => {
	const data = {
		productId: sentProductId,
		type: sentType,
	};
	try {
		const response = await axiosInstance.delete("setup/product/detail/delete", {
			data,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// product bundle details api
// get all product bundle
export const getBundlePackage = async (comId) => {
	try {
		const response = await axiosInstance.get(
			`setup/product/package?comId=${comId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save product details
export const saveBundlePackage = async (
	sentComId,
	sentStoreId,
	sentCode,
	sentName,
	sentStatus,
	sentDesc,
	sentStartDate,
	sentStopDate,
	sentProduct,
	sentPriceRange
) => {
	const data = {
		comId: sentComId,
		storeId: sentStoreId,
		code: sentCode,
		name: sentName,
		status: sentStatus,
		desc: sentDesc,
		startDate: sentStartDate,
		stopDate: sentStopDate,
		product: sentProduct,
		priceRange: sentPriceRange,
	};
	try {
		const response = await axiosInstance.post(
			"setup/product/package/store",
			data
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete product details
export const deleteBundlePackage = async (sentProductId, sentType) => {
	const data = {
		productId: sentProductId,
		type: sentType,
	};
	try {
		const response = await axiosInstance.delete(
			"setup/product/package/delete",
			{ data }
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// shipping api
// get all shipping provider
export const getShippingProvider = async (comId) => {
	try {
		const response = await axiosInstance.get(
			`setup/ship/provider?comId=${comId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save shipping provider
export const saveShippingProvider = async (
	sentComId,
	sentStoreId,
	sentUsrOrgId,
	sentCourCategory,
	sentCourCom,
	sentCourDetails,
	sentImage
) => {
	const data = {
		comId: sentComId,
		storeId: sentStoreId,
		usrOrgId: sentUsrOrgId,
		courCategory: sentCourCategory,
		courCom: sentCourCom,
		courDetails: sentCourDetails,
		image: sentImage,
	};
	try {
		const response = await axiosInstance.post(
			"setup/ship/provider/store",
			data
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// disable shipping provider
export const disableShippingProvider = async (sentShipId) => {
	const data = {
		shipId: sentShipId,
	};
	try {
		const response = await axiosInstance.post(
			"setup/ship/provider/disable",
			data
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// enable shipping provider
export const enableShippingProvider = async (sentShipId) => {
	const data = {
		shipId: sentShipId,
	};
	try {
		const response = await axiosInstance.post(
			"setup/ship/provider/enable",
			data
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete shipping provider
export const deleteShippingProvider = async (sentShipId) => {
	const data = {
		shipId: sentShipId,
	};
	try {
		const response = await axiosInstance.delete("setup/ship/provider/delete", {
			data,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get all shipping rate
export const getShippingRate = async (comId) => {
	try {
		const response = await axiosInstance.get(`setup/ship/rate?comId=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save shipping rate
export const saveShippingRate = async (
	sentRateId,
	sentShipProviderId,
	sentRegionId,
	sentRateWeight,
	sentRateCost
) => {
	const data = {
		rateId: sentRateId,
		shipProviderId: sentShipProviderId,
		regionId: sentRegionId,
		rateWeight: sentRateWeight,
		rateCost: sentRateCost,
	};
	try {
		const response = await axiosInstance.post("setup/ship/rate/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// disable shipping rate
export const disableShippingRate = async (sentRateId) => {
	const data = {
		rateId: sentRateId,
	};
	try {
		const response = await axiosInstance.post("setup/ship/rate/disable", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// enable shipping rate
export const enableShippingRate = async (sentRateId) => {
	const data = {
		rateId: sentRateId,
	};
	try {
		const response = await axiosInstance.post("setup/ship/rate/enable", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete shipping rate
export const deleteShippingRate = async (sentRateId) => {
	const data = {
		rateId: sentRateId,
	};
	try {
		const response = await axiosInstance.delete("setup/ship/rate/delete", {
			data,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// setup company api
// get company details
export const getCompanyDetails = async (comId) => {
	try {
		const response = await axiosInstance.get("/setup/company", {
			params: {
				comId: comId,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save company details
export const saveCompanyDetails = async (
	companyName,
	companyOrgId,
	companyRoc,
	companyAddress1,
	companyAddress2,
	companyPostcode,
	companyCity,
	companyState,
	companyCountry,
	companycode,
	companyPhone,
	companyEmail,
	companyBank,
	companyBankNumber,
	companyBankName,
	companyBankHolder,
	companyCurrency,
	companyPrefixName,
	comProvince,
	comRegency,
	comDistrict,
	comVillage
) => {
	const data = {
		companyName: companyName,
		orgId: companyOrgId,
		companyRoc: companyRoc,
		comAddr1: companyAddress1,
		comAddr2: companyAddress2,
		comPostcode: companyPostcode,
		comCity: companyCity,
		comState: companyState,
		comCountry: companyCountry,
		comCodePhone: companycode,
		comPhone: companyPhone,
		comEmail: companyEmail,
		comBankCountry: companyCountry,
		comBankCom: companyBank,
		comBankNumber: companyBankNumber,
		comBankName: companyBankName,
		comBankIdentity: companyBankHolder,
		comCurrency: companyCurrency,
		comPrefixName: companyPrefixName,
		comProvince: comProvince,
		comRegency: comRegency,
		comDistrict: comDistrict,
		comVillage: comVillage
	};
	try {
		const response = await axiosInstance.post("setup/company/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get company images
export const getCompanyImages = async (comId) => {
	try {
		const response = await axiosInstance.get("/setup/company-theme", {
			params: {
				comId: comId,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// update company images
export const updateCompanyImages = async (comId, dataSent) => {
	let formData = new FormData();
	formData.append("comId", comId);
	formData.append("logo", dataSent.logo);

	if (dataSent.logoDark) formData.append("logoDark", dataSent.logoDark);
	if (dataSent.loginImage) formData.append("loginImage", dataSent.loginImage);

	try {
		const response = await axiosInstance.post(
			"/setup/company-theme/store-logo",
			formData
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const updateHomepageImages = async (comId, loginImage) => {
    let formData = new FormData();
    formData.append("comId", comId);
    formData.append("loginImage", loginImage);

    try {
        const response = await axiosInstance.post(
            "/setup/company-theme/store-logo",
            formData
        );
        return response;
    } catch (error) {
        throw handleErrorResponse(error);
    }
};

export const updateCompanyTheme = async (comId, primary = null, secondary = null) => {
	let formData = new FormData();
	formData.append("comId", comId);
	if (primary) formData.append("dColorPrimary", primary);
	if (secondary) formData.append("dColorSecondary", secondary);

	try {
		const response = await axiosInstance.post(
			"/setup/company-theme/store",
			formData
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get company social network
export const getCompanyLinks = async () => {
	try {
		const response = await axiosInstance.get("/setup/company-network");
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// update company details
export const updateCompanyLinks = async (dataPage) => {
	let data = {
		socmedFb: dataPage.socmed.facebook,
		socmedIg: dataPage.socmed.instagram,
		socmedTiktok: dataPage.socmed.tiktok,
		socmedTwitter: dataPage.socmed.twitter,
		ecommShopee: dataPage.ecomm.shopee,
		ecommLazada: dataPage.ecomm.lazada,
		ecommCarousell: dataPage.ecomm.carousell,
		ecommMudah: dataPage.ecomm.mudah,
	};
	try {
		const response = await axiosInstance.post(
			"/setup/company-network/store",
			data
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// setup term and condition api
// get all term and condition
export const getTermCondition = async (comId) => {
	try {
		const response = await axiosInstance.get(`setup/tnc?comId=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get term and condition by tier
export const getTermConditionTier = async (comId, tierId) => {
	try {
		const response = await axiosInstance.get("setup/tnc", {
			params: {
				comId: comId,
				tierId: tierId,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save term and condition
export const saveTermCondition = async (
	sentCompanyId,
	sentTierId,
	sentTncDetails,
	sentPdfUpload
) => {
	const data = {
		companyId: sentCompanyId,
		tierId: sentTierId,
		tncDetails: sentTncDetails,
		pdfUpload: sentPdfUpload,
	};
	try {
		const response = await axiosInstance.post("setup/tnc/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete term and condition
export const deleteTermCondition = async (sentTermId) => {
	const data = {
		id: sentTermId,
	};
	try {
		const response = await axiosInstance.delete("setup/tnc/delete", { data });
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// setup welcoming email api
// get all welcoming email
export const getWelcomingEmail = async (comId) => {
	try {
		const response = await axiosInstance.get(`setup/greeting?comId=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save term and condition
export const saveWelcomingEmail = async (
	sentCompanyId,
	sentTierId,
	sentGreetSubject,
	sentGreetDetail
) => {
	const data = {
		companyId: sentCompanyId,
		tierId: sentTierId,
		greetSubject: sentGreetSubject,
		greetDetail: sentGreetDetail,
	};
	try {
		const response = await axiosInstance.post("setup/greeting/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete term and condition
export const deleteWelcomingEmail = async (sentWelcomeId) => {
	const data = {
		id: sentWelcomeId,
	};
	try {
		const response = await axiosInstance.delete("setup/greeting/delete", {
			data,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// setup company controls
// get company controls
export const getCompanyControls = async (comId) => {
	try {
		const response = await axiosInstance.get(`setup/company-features?comId=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// update company control
export const updateCompanyControls = async (comId, dataSent) => {
	const data = {
		comId: comId,
		showHidePreorder: dataSent.showHidePreorder,
		manualReceipt: dataSent.manualReceipt,
		selfPickup: dataSent.selfPickup,
		firstOrder: dataSent.firstOrder,
		moq: dataSent.moq,
		orderCompany: dataSent.orderCompany,
		bookingTime: dataSent.bookingTime,
		allLevel: dataSent.allLevel,
		allLevelStatus: dataSent.allLevelStatus,
		level: dataSent.level
	};
	try {
		const response = await axiosInstance.post("/setup/company-features/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// setup notification
// get company notification
export const getCompanyNotificationControls = async (comId) => {
	try {
		const response = await axiosInstance.get(`setup/company-features/notification?comId=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// store email address
export const storeEmailAddress = async (comId, dataPage) => {
	const data = {
		comId: comId,
		emailAddress: dataPage.emailAddress
	};
	try {
		const response = await axiosInstance.post("/setup/company-features/store-email-address", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// store notif control
export const storeNotifControl = async (comId, state, type) => {
	const data = {
		comId: comId,
		state: state,
		type: type,
	};
	try {
		const response = await axiosInstance.post("/setup/company-features/store-notif-control", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// setup Hall of fame
// get all hall of fame
export const getHallFame = async (comId) => {
	try {
		const response = await axiosInstance.get(`setup/hof?comId=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save hall of fame
export const saveHallFame = async (comId, dataSent) => {
	try {
		let formData = new FormData();
		if (dataSent.id) {
			formData.append("id", dataSent.id)
		}
		formData.append("companyId", comId)
		formData.append("hofTitle", dataSent.hofTitle)
		formData.append("hofDate", dataSent.hofDate)
		formData.append("userName", dataSent.usrName)
		formData.append("userAchievement", dataSent.usrAchievement)
		if (dataSent.imgHof != null) {
			formData.append("userImg", dataSent.imgHof)
		}
		const response = await axiosInstance.post("setup/hof/store", formData);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete hall of fame
export const deleteHallFame = async (senthofId) => {
	const data = {
		id: senthofId,
	};
	try {
		const response = await axiosInstance.delete("setup/hof/delete", { data });
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// setup news feed api
// get all news feed
export const getNewsFeed = async (comId) => {
	try {
		const response = await axiosInstance.get(`setup/news-feed?comId=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// save news feed

export const saveNewsFeed = async (dataSent, file) => {
	try {
		const formData = new FormData();
        formData.append('pin', dataSent.pin);
        formData.append('companyId', dataSent.companyId);
        formData.append('usrOrgId', dataSent.usrOrgId);
        // formData.append('videoId', dataSent.videoId);
        formData.append('subject', dataSent.subject);
        formData.append('content', dataSent.content);
        if (file) formData.append('file', file);
        if (dataSent.videoId) formData.append('videoId', dataSent.videoId);

		const response = await axiosInstance.post("setup/news-feed/store", formData);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// update pin
export const updatePin = async (
	id,
	pin
) => {
	const data = {
		pin: pin,
		id: id,
	};
	try {
		const response = await axiosInstance.post("setup/news-feed/updatePin", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete news feed
export const deleteNewsFeed = async (sentNewsFeedId) => {
	const data = {
		id: sentNewsFeedId,
	};
	try {
		const response = await axiosInstance.delete("setup/news-feed/delete", {
			data,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// marketing tools
// get all folder
export const getListFolderByComId = async (comId, tierId = null, foldername = null) => {
	try {
		const response = await axiosInstance.get('/marketing/folder', {
			params: {
				comId: comId,
				tierId: tierId,
				folderName: foldername
			}
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get folder id
export const getListFolderById = async (folderId) => {
	try {
		const response = await axiosInstance.get(
			`/marketing/folder/id?folderId=${folderId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get folder total
export const getListFolderTotal = async (comId) => {
	try {
		const response = await axiosInstance.get(
			`/marketing/folder/total?comId=${comId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// add folder by per tier
export const saveFolderPerTier = async (
	sentcomId,
	sentfolderName,
	senttier
) => {
	const data = {
		comId: sentcomId,
		folderName: sentfolderName,
		tier: senttier,
	};
	try {
		const response = await axiosInstance.post("/marketing/folder/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// add folder by all tier
export const saveFolderAllTier = async (sentcomId, sentfolderName) => {
	const data = {
		comId: sentcomId,
		folderName: sentfolderName,
	};
	try {
		const response = await axiosInstance.post("/marketing/folder/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// edit folder name
export const saveFolderName = async (sentId, sentcomId, sentfolderName) => {
	const data = {
		id: sentId,
		comId: sentcomId,
		folderName: sentfolderName,
	};
	try {
		const response = await axiosInstance.post("/marketing/folder/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete folder name
export const deleteFolderId = async (sentFolderId) => {
	const data = {
		id: sentFolderId,
	};
	try {
		const response = await axiosInstance.delete("/marketing/folder/delete", {
			data,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get all list file
export const getListFile = async (folderId) => {
	try {
		const response = await axiosInstance.get(
			`/marketing/file?folderId=${folderId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// add new file
export const saveAddFile = async (sentfolderId, sentfileName) => {
	let formData = new FormData();
	formData.append("folderId", sentfolderId);
	formData.append("fileName", sentfileName);
	try {
		const response = await axiosInstance.post("/marketing/file/store", formData);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};


// edit file name
export const saveFileName = async (sentfolderId, sentfileName) => {
	let formData = new FormData();
	formData.append("folderId", sentfolderId);
	formData.append("fileName", sentfileName);
	try {
		const response = await axiosInstance.post("/marketing/file/store", formData);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete file
export const deleteFileId = async (sentFileId) => {
	const data = {
		id: sentFileId,
	};
	try {
		const response = await axiosInstance.delete("/marketing/file/delete", {
			data,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// download file
export const downloadFile = async (fileId) => {
	try {
		const response = await axiosInstance.get("/marketing/file/download", {
			params: {
				id: fileId
			},
			responseType: 'blob',
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// setup helpline
// amir: need api for helpline
export const getHelplineList = async (comId) => {
	try {
		const response = await axiosInstance.get(`/helpline?comid=${comId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//--------------SCORE AND RANKING---------//

//top performer
export const topPerformer = async (comId, date, limit = 3) => {
	try {
		const response = await axiosInstance.get(
			'/scoreranking/top-performer', {
			params: {
				comId: comId,
				priode: date,
				limit: limit
			}
		}
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//top state
export const topState = async (comId, date) => {
	try {
		const response = await axiosInstance.get("/scoreranking/top-state", {
			params: {
				comId: comId,
				priode: date
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//personal sales
export const personalSales = async (comId, date, tier, limit) => {
	try {
		const response = await axiosInstance.get("/scoreranking/score-ranking-ps", {
			params: {
				comId: comId,
				date: date,
				tier: tier,
				limit: limit
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//group sales
export const groupSales = async (comId, date, tier, limit) => {
	try {
		const response = await axiosInstance.get("/scoreranking/score-ranking-gs", {
			params: {
				comId: comId,
				date: date,
				tier: tier,
				limit: limit
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//personal group sales
export const personalgroupSales = async (comId, date, tier, limit) => {
	try {
		const response = await axiosInstance.get("/scoreranking/score-ranking-tpgs", {
			params: {
				comId: comId,
				date: date,
				tier: tier,
				limit: limit
			},
		}
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get voucher by id
export const getVoucher = async (
	comId,
	page = null,
	name = null,
	status = null
) => {
	try {
		const response = await axiosInstance.get("setup/voucher", {
			params: {
				comId: comId,
				page: page,
				name: name,
				status: status,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getVoucherId = async (id) => {
	try {
		const response = await axiosInstance.get(`setup/voucher?id=${id}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const voucherStore = async (dataSent) => {
	let data = {
		id: dataSent.id,
		comId: dataSent.comId,
		voucherName: dataSent.voucherName,
		voucherType: dataSent.voucherType,
		voucherCode: dataSent.voucherCode,
		voucherQuota: dataSent.voucherQuota,
		voucherStartDate: dataSent.voucherStartDate,
		voucherStopDate: dataSent.voucherStopDate,
		voucherDisc: dataSent.voucherDisc,
		voucherMinPurchase: dataSent.voucherMinPurchase,
		voucherUsgLimit: dataSent.voucherUsgLimit,
		freeShipping: dataSent.freeShipping,
		firstOrder: dataSent.firstOrder,
		package: dataSent.package,
	};
	try {
		const response = await axiosInstance.post("/setup/voucher/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const voucherDelete = async (id) => {
	try {
		const response = await axiosInstance.delete("/setup/voucher/delete", {
			params: {
				id: id,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getVoucherHistory = async (id) => {
	try {
		const response = await axiosInstance.get(`/setup/voucher/history?id=${id}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getBillplzBankList = async (category, storeId) => {
	try {
		const response = await axiosInstance.get('/integration/billplz/getBank', {
			params: {
				category: category,
				storeId: storeId,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
//setup FAQ
export const faqList = async (companyId) => {
	try {
		const response = await axiosInstance.get(`/setup/faq?comId=${companyId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const faqTier = async (companyId, tierId) => {
	try {
		const response = await axiosInstance.get('/setup/faq', {
			params: {
				comId: companyId,
				tier: tierId,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const saveFaq = async (
	idFaq,
	comId,
	questionFaq,
	answerFaq,
	idLevel
) => {
	const data = {
		id: idFaq,
		companyId: comId,
		question: questionFaq,
		answer: answerFaq,
		tierId: idLevel,
	};
	try {
		const response = await axiosInstance.post("/setup/faq/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
export const deleteFaq = async (faqId) => {
	const data = {
		id: faqId,
	};
	try {
		const response = await axiosInstance.delete("/setup/faq/delete", {
			data,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
//------orderCartDetails------//
export const orderCart = async (store_id) => {
	try {
		const response = await axiosInstance.get(
			`/sales/my-shop/get-cart?storeId=${store_id}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
//---- Get Province
export const getProvince = async () => {
	try {
		const response = await axiosInstance.get(`/attribute/province`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//---- Get Regency or City
export const getRegency = async (provinceId) => {
	try {
		const response = await axiosInstance.get(`/attribute/city?provinceId=${provinceId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//---- Get District
export const getDistrict = async (regencyId) => {
	try {
		const response = await axiosInstance.get(`/attribute/districts?cityId=${regencyId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//---- Get Village
export const getVillage = async (districtId) => {
	try {
		const response = await axiosInstance.get(`/attribute/village?districtId=${districtId}`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//----Customer address list user order
export const customerList = async () => {
	try {
		const response = await axiosInstance.get(`/sales/customer`);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
export const setPrimaryAddress = async (customerId) => {
	const data = {
		id: customerId,
		status: "yes",
	};
	try {
		const response = await axiosInstance.post(
			"/sales/customer/set-primary",
			data
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
// save customer address details
export const saveAddressCustomer = async (
	companyCountry,
	customerId,
	customerFirstName,
	customerLastName,
	customerTitle,
	customerEmail,
	customerAddress1,
	customerAddress2,
	customerPostcode,
	customerCity = null,
	customerState = null,
	customerCountry,
	customerPhoneCode,
	customerPhoneNumber,
	customerProvince = null,
	customerRegency = null,
	customerDistricts = null,
	customerVillage = null
) => {
	const data = {
		companyCountry: companyCountry,
		id: customerId,
		firstName: customerFirstName,
		lastName: customerLastName,
		addrTitle: customerTitle,
		email: customerEmail,
		address1: customerAddress1,
		address2: customerAddress2,
		postCode: customerPostcode,
		city: customerCity,
		state: customerState,
		country: customerCountry,
		phoneCode: customerPhoneCode,
		phoneNumber: customerPhoneNumber,
		villageId: customerVillage,
		districtId: customerDistricts,
		regencyId: customerRegency,
		provinceId: customerProvince
	};
	try {
		const response = await axiosInstance.post("sales/customer/store", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
export const deleteCustomerAddress = async (customerId) => {
	const data = {
		id: customerId,
	};
	try {
		const response = await axiosInstance.delete("/sales/customer/delete", {
			data,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
// receipt paymentupload
export const uploadReceiptPayment = async (receipt, totalOrder, store_id) => {
	let formData = new FormData();
	formData.append("receipt", receipt);
	formData.append("total", totalOrder);
	formData.append("storeId", store_id);
	try {
		const response = await axiosInstance.post(
			"sales/my-shop/upload-receipt",
			formData
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
// checkout
export const checkoutPayment = async (
	store_id,
	customer_id,
	selected_shipment,
	selected_payment,
	shipment_id,
	payment_id,
	total_paid
) => {
	const data = {
		storeId: store_id,
		customerId: customer_id,
		shipmentType: selected_shipment,
		paymentType: selected_payment,
		shipmentId: shipment_id,
		paymentId: payment_id,
		totalPaid: total_paid,
	};
	try {
		const response = await axiosInstance.post("sales/my-shop/checkout", data);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
// checkout ejenpay
export const checkoutEjenPay = async (
	order_id,
	nameUser,
	emailUser,
	totalPaid,
	bankChoosed,
	category,
	payType
) => {
	const data = {
		orderId: order_id,
		customerName: nameUser,
		customerEmail: emailUser,
		totalPaid: totalPaid,
		bankCode: bankChoosed,
		category: category,
		payType: payType
	};
	try {
		const response = await axiosInstance.post(
			"integration/billplz/checkout",
			data
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
//api to hide payment method
export const getAllAdminFees = async (comId) => {
	try {
		const response = await axiosInstance.get(
			`integration/billplz/get-admin-fee?comId=${comId}`
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
export const topUpEjenPay = async (
	nameUser,
	emailUser,
	totalPaid,
	bankChoosed
) => {
	const data = {
		customerName: nameUser,
		customerEmail: emailUser,
		totalPaid: totalPaid,
		bankCode: bankChoosed,
	};
	try {
		const response = await axiosInstance.post(
			"integration/billplz/top-up-credit",
			data
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// list of Shipping Method In company
export const listShippingMethod = async (
	company_id,
	customer_state,
	customer_country,
	total_weight,
	totalPaid,
	total_item,
) => {
	const params = {
		comId: company_id,
		state: customer_state,
		country: customer_country,
		weightTotal: total_weight,
		totalPaid: totalPaid,
		total_item: total_item
	};
	try {
		const response = await axiosInstance.get("/sales/ship-method", { params });
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
// list of Payment Method In company
export const listPaymentMethod = async (company_id) => {
	const params = {
		comId: company_id,
	};
	try {
		const response = await axiosInstance.get("/sales/payment-method", {
			params,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
//user commission sumamrise
export const userCommissionSummary = async (month, year, user_id = null) => {
	const params = {
		month: month,
		year: year,
		userId: user_id,
	};
	try {
		const response = await axiosInstance.get("/commission", {
			params,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getTotalCommissionStat = async (month, year, user_id) => {
	const params = {
		month: month,
		year: year,
		userId: user_id,
	};
	try {
		const response = await axiosInstance.get("/commission/company", {
			params,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};


export const getOverridingList = async (month, year, page, user_id, tier_id) => {
	const params = {
		month: month,
		year: year,
		page: page,
		userId: user_id,
		tierId: tier_id
	};
	try {
		const response = await axiosInstance.get("commission/details?type=override", {
			params,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getHelperList = async (month, year, page, user_id, tier_id) => {
	const params = {
		month: month,
		year: year,
		page: page,
		userId: user_id,
		tierId: tier_id
	};
	try {
		const response = await axiosInstance.get("commission/details?type=helper", {
			params,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getSaleProfitList = async (month, year, page, user_id, tier_id) => {
	const params = {
		month: month,
		year: year,
		page: page,
		userId: user_id,
		tierId: tier_id
	};
	try {
		const response = await axiosInstance.get("commission/details?type=sale-profit", {
			params,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getAffiliateList = async (month, year, page, user_id, tier_id) => {
	const params = {
		month: month,
		year: year,
		page: page,
		userId: user_id,
		tierId: tier_id
	};
	try {
		const response = await axiosInstance.get("commission/details?type=affiliate", {
			params,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};
// campaign

// list campaign based on company
export const getCampaignList = async (comId,
	status = null,
	name = null, page = null) => {
	const params = {
		comId: comId,
		status: status,
		name: name,
		page: page
	};
	try {
		const response = await axiosInstance.get("/game/campaign", {
			params,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// list campaign active
export const getCampaignActive = async (comId, filter = null) => {
	try {
		const response = await axiosInstance.get("/game/campaign/get-active", {
			params: {
				comId: comId,
				filter: filter
			}
		})
		return response
	} catch (error) {
		throw handleErrorResponse(error)
	}
}

// add new campaign
export const addCampign = async (dataSent) => {
	let formData = new FormData();
	formData.append("comId", dataSent.comId);
	formData.append("gameTitle", dataSent.gameTitle);
	formData.append("gameDesc", dataSent.gameDesc);
	formData.append("gameTnc", dataSent.gameTnc);
	formData.append("rewardType", dataSent.rewardType);
	formData.append("pointRequired", dataSent.pointRequired);
	formData.append("startDate", dataSent.campaignStartDate);
	formData.append("endDate", dataSent.campaignStopDate);
	formData.append("previous", dataSent.previous);
	formData.append("image1", dataSent.image1);
	// formData.append("image2", dataSent.image2);
	// formData.append("image3", dataSent.image3);
	if (dataSent.maxRedeem !== null) {
		formData.append("maxRedeem", dataSent.maxRedeem);
	}
	try {
		const response = await axiosInstance.post("/game/campaign/store", formData);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// edit campaign
export const editCampign = async (dataSent) => {
	let formData = new FormData();
	formData.append("id", dataSent.id);
	formData.append("comId", dataSent.comId);
	formData.append("gameTitle", dataSent.gameTitle);
	formData.append("gameDesc", dataSent.gameDesc);
	formData.append("gameTnc", dataSent.gameTnc);
	formData.append("rewardType", dataSent.rewardType);
	formData.append("pointRequired", dataSent.pointRequired);
	formData.append("startDate", dataSent.campaignStartDate);
	formData.append("endDate", dataSent.campaignStopDate);
	formData.append("previous", dataSent.previous);
	if (dataSent.maxRedeem !== null) {
		formData.append("maxRedeem", dataSent.maxRedeem);
	}
	if(dataSent.image1){
		formData.append("image1", dataSent.image1);
	}
	try {
		const response = await axiosInstance.post("/game/campaign/edit", formData);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getSummaryCampaign = async () => {
	try {
		const response = await axiosInstance.get('/game/campaign/summary');
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get by campaign id
export const getCampaignId = async (id, comId) => {
	try {
		const response = await axiosInstance.get('/game/campaign/campaign-detail', {
			params: {
				id: id,
				comId: comId
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// delete campaign
export const campaignDelete = async (id) => {
	try {
		const response = await axiosInstance.delete("/game/campaign/delete", {
			params: {
				id: id,
			},
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const campaignReedem = async (agentId, campaignId) => {
	try {
		const data = {
			userId: agentId,
			campaignId: campaignId
		}
		const response = await axiosInstance.post('/game/campaign/redeem-point', data)
		return response
	} catch (error) {
		throw handleErrorResponse(error)
	}
}

export const getHistoryPoint = async (type) => {
	try {
		const response = await axiosInstance.get('/game/campaign/history-point', {
			params: {
				type: type,
			},
		})

		return response
	} catch (error) {
		throw handleErrorResponse(error)
	}
}

export const getHistoryRedeem = async (type, comId) => {
	try {
		const response = await axiosInstance.get('/game/campaign/history-redeem', {
			params: {
				type: type,
				comId: comId
			},
		})

		return response
	} catch (error) {
		throw handleErrorResponse(error)
	}
}

// agent promote demote level
// get list agent
export const getListAllAgent = async (
	page = null,
	comId = null,
	search = null,
	upline = null,
	helper = null,
	status = null,
	month = null,
	monthTomonth = null,
	sortField = null,
	sortOrder = null,
	kpi = null,
	kpiGs = null,
	kpiTpgs = null) => {
	try {
		const response = await axiosInstance.get("/promote-demote", {
			params: {
				page: page,
				comId: comId,
				search: search,
				upline: upline,
				helper: helper,
				status: status,
				month: month,
				date: monthTomonth,
				kpi: kpi,
				kpiGs: kpiGs,
				kpiTpgs: kpiTpgs,
				sortField: sortField,
				sortOrder: sortOrder,
			}
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getAllAgentSummary = async (comId) => {
	try {
		const response = await axiosInstance.get("/promote-demote/summary", {
			params: {
				comId: comId
			}
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get upline and helper
export const getListUplineHelper = async (level, type, usr_org_id) => {

	try {
		const response = await axiosInstance.get("/promote-demote/upline-list", {
			params: {
				level: level,
				type: type,
				usr_org_id: usr_org_id
			}
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// get history reedem
export const historyRedeem = async (comId, gameId, page, search) => {
	try {
		const response = await axiosInstance.get("/game/campaign/redeem-list", {
			params: {
				comId: comId,
				page: page,
				search: search,
				game: gameId
			}
		})

		return response
	} catch (error) {
		throw handleErrorResponse(error)
	}
}

// update upline
export const changeUpline = async (dataSent) => {
	let data = {
		id: dataSent.id,
		type: dataSent.type,
		uplineId: dataSent.uplineId,
		helperId: dataSent.helperId
	}
	try {
		const $response = await axiosInstance.post("/promote-demote/store", data);
		return $response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

// suspend unsuspend agent
export const suspendUnsuspendAgent = async (id, type) => {
	let data = {
		id: id,
		type: type,
	}
	try {
		const $response = await axiosInstance.post("/promote-demote/suspend", data);
		return $response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

//settlement report
export const getTableReport = async (comId, page, data) => {
	try {
		const response = await axiosInstance.get("/settlement/reportdata", {
			params: {
				comId: comId,
				page: page,
				query1: data.searchQuery1,
				dateOrder: data.dateOrder,
				dateSettlement: data.dateSettlement,
				paymentMethod: data.paymentMethod,
				currency: data.currency,
				settStatus: data.settStatus
			}
		})

		return response
	} catch (error) {
		throw handleErrorResponse(error)
	}
}

export const getPaymentMethod = async (comId) => {
	try {
		const response = await axiosInstance.get("/settlement/getpayment", {
			params: {
				comId: comId,
			}
		})

		return response
	} catch (error) {
		throw handleErrorResponse(error)
	}
}

export const downloadSettlementReport = async (comId) => {
	try {
		const response = await axiosInstance.get('/settlement/downloaddata',
			{
				params: {
					comId: comId
				},
			}
		);
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const getZeroriseCommissionHistory = async (type, date, usr_org_id = null) => {
	try {
		const response = await axiosInstance.get('/zerorise/commission/history', {
			params: {
				type: type,
				date: date,
				usr_org_id: usr_org_id,
			},
		});
		return response;
	} catch(error){
		throw handleErrorResponse(error);
	}
};

// membership survey
export const checkResponseMembership = async (usr_org_id, com_id) => {
	try {
		const response = await axiosInstance.get('/membership/survey/check-response', {
			params: {
				com_id: com_id,
				usr_id: usr_org_id,
			},
		});
		return response;
	} catch(error){
		throw handleErrorResponse(error);
	}
};
export const saveResponseMembership = async (usr_org_id, com_id, surveyResponse = 1) => {
	try {
		const response = await axiosInstance.post("/membership/survey/store", {
			usr_org_id: usr_org_id,
			com_id: com_id,
			response: surveyResponse,
		});
		return response;
	} catch (error) {
		throw handleErrorResponse(error);
	}
};

export const changeLanguage = async (lang) => {
    try {
        const response = await axiosInstance.post('/attribute/language',{
            lang : lang
        })

        return response
    } catch (error) {
        throw handleErrorResponse(error)
    }
}

export const storeEjenflex = async (usrOrg, comId) => {
    try {
        const response = await axiosInstance.post('/ejenflex',{
            usrOrg : usrOrg,
			comId : comId
        })

        return response
    } catch (error) {
        throw handleErrorResponse(error)
    }
}

export const getEjenflex = async (usrOrg, comId) => {
    try {
        const response = await axiosInstance.get('/ejenflex',{
            params : {
				usrOrg : usrOrg,
				comId : comId
			}
        })

        return response
    } catch (error) {
        throw handleErrorResponse(error)
    }
}

export default {
	axiosInstance,
	login,
	branch,
	region,
};
