import { createStore } from 'vuex'

import modules from './modules'

const store = createStore({
  modules,
  state: {
    logoUrl: null,
    layoutMode: localStorage.getItem('displayMode') ? localStorage.getItem('displayMode') : 'light',
    loading: false,
    membership: true
  },
  mutations: {
    // ... your existing mutations

    // Add a mutation to set the logo URL
    setLogoUrl(state, url) {
      state.logoUrl = url;
    },
    setLayoutMode(state, value){
      state.layoutMode = value
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setMembershipModal(state, value) {
      state.membership = value;
    }
  },

  actions: {
    toggleDarkMode({ commit, state }) {
      if (state.layoutMode === 'dark') {
        localStorage.setItem('displayMode', 'light');
        document.documentElement.setAttribute('data-layout-mode', 'light');
        commit('setLayoutMode', 'light');
      } else {
        localStorage.setItem('displayMode', 'dark');
        document.documentElement.setAttribute('data-layout-mode', 'dark');
        commit('setLayoutMode', 'dark');
      }
    },
    setLoading({ commit }, payload) {
      commit("setLoading", payload);
    },
    setMembershipModal({ commit }, value) {
      commit("setMembershipModal", value);
    }
  },
  
  getters: {
    membership: state => state.membership
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store

